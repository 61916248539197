.OneRepMax-Main{
    color: white;
}

.OneRepMax-Input{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.OneRepMax-img{
    width: 80vw;
}

.OneRepMax-Button{
    background: red;
    font-size: 2vw;
    font-weight: bold;
    border: none;
    border-radius: 10px;
}