.home-main{
    color: white;
}

.home-img{
    width: 80vw;
}

.home-title{
    font-size: 4vw;
    background-color: grey;
}

.home-links{
    background-color: grey;
    color: white;
    font-size: 2vw;
    margin-bottom: 20px;
    font-style: italic;
    padding-top: 2vh;
    padding-bottom: 2vh;
    display: grid;
    grid-template-columns: auto auto;
    
}

.link{
    color: black;
    text-decoration: underline;
    display: inline-grid;
}

.link:hover{
    color: red;
}